// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

// getReports
export const getReports = createAsyncThunk('reports/getReports', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL +
      `/api/dashboard/superAdmin/orders?with_pagination=yes&order=${params?.sort}&page=${params?.page}&per_page=${params?.per_page}`,
    {
      params
    }
  )
  return {
    params,
    data: response?.data?.data?.data,
    totalPages: response.data.data?.meta?.total,
    loading: true
  }
})

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    reportsData: [],
    total: 1,
    reportsStatus: 'idle'
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getReports.fulfilled, (state, action) => {
        state.reportsData = action.payload.data
        state.reportsStatus = 'succeeded'
        state.total = action.payload.totalPages
      })
      .addCase(getReports.pending, (state, action) => {
        state.reportsStatus = 'loading'
      })
  }
})

export default reportsSlice.reducer
