// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import notifications from './notification'
import reset from '@src/views/pages/authentication/store'
import admins from '@src/views/apps/admins/store'
import owners from '@src/views/apps/owners/store'
import reviews from '@src/views/apps/reviews/store'
import orders from '@src/views/apps/orders/store'
import links from '@src/views/apps/social-links/store'
import users from '@src/views/apps/users/store'
import categoryTypes from '@src/views/apps/category-types/store'
import deliveryTypes from '@src/views/apps/delivery-types/store'
import choiceGroups from '@src/views/apps/choice-groups/store'
import restaurants from '@src/views/apps/restaurants/store'
import settings from '@src/views/apps/setting/store'
import roles from '@src/views/apps/roles/store'
import drinks from '@src/views/apps/drinks/store'
import sliders from '@src/views/apps/sliders/store'
import dataTables from '@src/views/tables/data-tables/store'
import privacyPolicy from '@src/views/apps/privacy-policy/store'
import terms from '@src/views/apps/terms-conditions/store'
import coupons from '@src/views/apps/coupons/store'
import reports from '@src/views/apps/reports/store'

const rootReducer = {
  auth,
  navbar,
  notifications,
  admins,
  reviews,
  orders,
  links,
  settings,
  sliders,
  roles,
  drinks,
  restaurants,
  choiceGroups,
  categoryTypes,
  deliveryTypes,
  owners,
  users,
  layout,
  reset,
  dataTables,
  privacyPolicy,
  terms,
  coupons,
  reports
}

export default rootReducer
