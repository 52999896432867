// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('settings/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/settings')
  return response.data.data
})

export const getData = createAsyncThunk('settings/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/settings', params)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
    loading: true
  }
})

export const updateUser = createAsyncThunk('settings/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/settings/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().settings.params))
  return params.user
})
export const updatePrivacy = createAsyncThunk('settings/updatePrivacy', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/settings/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().settings.params))
  return params.user
})
export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    data: null,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
  }
})

export default settingsSlice.reducer
