// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('sliders/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})
export const resetUser = createAsyncThunk('sliders/resetUser', async () => {
  return null
})
export const getData = createAsyncThunk('sliders/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/home/slider', params)

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: false
  }
})
export const getRestaurants = createAsyncThunk('sliders/getRestaurants', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/restaurants?with_pagination=no'
  )
  return response.data.data
})
export const getUser = createAsyncThunk('sliders/getUser', async id => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-slider/${id}`)
  return {
    data: response.data.data[0],
    loading: false
  }
})

export const addUser = createAsyncThunk('sliders/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/home/slider', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().sliders.params))
  return user
})

export const updateUser = createAsyncThunk('categoryData/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/home/slider/${params.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().sliders.params))
  return params.user
})

export const deleteUser = createAsyncThunk('sliders/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/home/slider/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().sliders.params))
  return id
})

export const slidersSlice = createSlice({
  name: 'sliders',
  initialState: {
    data: null,
    sliderData: null,
    total: 1,
    params: {},
    allData: [],
    restaurants: [],
    selectedUser: null,
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getRestaurants.fulfilled, (state, action) => {
        state.restaurants = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
        state.selectedUser = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.sliderData = action.payload.data
        // state.selectedUser = action.payload
        state.loading = action.payload.loading
      })
  }
})

export default slidersSlice.reducer
