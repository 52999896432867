import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const lang = localStorage.i18nextLng
export const successAlert = () => {
  return MySwal.fire({
    title: lang === 'en' ? 'Good job!' : 'Hyvää työtä!',
    text: lang === 'en' ? 'Your work has been completed!' : 'Työsi on valmis!',
    icon: 'success',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  })
}

export const warningAlert = message => {
  return MySwal.fire({
    title: lang === 'en' ? 'Warning!' : 'Varoitus!',
    text: message || 'You clicked the button!',
    icon: 'warning',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  })
}

export const errorAlert = message => {
  return MySwal.fire({
    title: lang === 'en' ? 'Oops...' : 'Oho...',
    text: message ? message : lang === 'en' ? 'Something went Wrong!' : 'Jotain meni pieleen!',
    icon: 'error',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  })
}

export const confirmAlert = (id, Action) => {
  return MySwal.fire({
    title: lang === 'en' ? 'Are you sure?' : 'Oletko varma',
    text: lang === 'en' ? "You won't be able to revert this!" : 'Et voi palauttaa tätä!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: lang === 'en' ? 'Yes!' : 'Joo!',
    cancelButtonText: lang === 'en' ? 'No' : 'كلا',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-danger ms-1'
    },
    buttonsStyling: false
  }).then(function (result) {
    if (result.value) {
      Action(id)
    }
  })
}
