// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

// getCoupons
export const getCoupons = createAsyncThunk('coupons/getCoupons', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL +
      `/api/dashboard/superAdmin/coupons?with_pagination=yes&page=${params?.page}&sort_by=id&sort=${params?.sort}&per_page=${params?.per_page}`,
    {
      params
    }
  )
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data?.meta?.total,
    loading: true
  }
})

// showCoupon
export const showCoupon = createAsyncThunk('coupons/showCoupon', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/coupons/${params?.id}`)
  return {
    data: response.data.data,
    loading: true
  }
})

// addCoupon
export const addCoupon = createAsyncThunk('coupons/addCoupon', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/coupons', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCoupons(getState().coupons.params))

  return params
})

// updateCoupon
export const updateCoupon = createAsyncThunk('coupons/updateCoupon', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/coupons/${params?.id}`, params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCoupons(getState().coupons.params))

  return params
})

// deleteCoupon
export const deleteCoupon = createAsyncThunk('coupons/deleteCoupon', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/superAdmin/coupons/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCoupons(getState().coupons.params))

  return id
})

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState: {
    couponsData: [],
    params: {},
    total: 1,
    coupon: {},
    couponsStatus: 'idle'
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCoupons.fulfilled, (state, action) => {
        state.couponsData = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
        state.couponsStatus = 'succeeded'
      })
      .addCase(getCoupons.pending, (state, action) => {
        state.couponsStatus = 'loading'
      })
      .addCase(showCoupon.fulfilled, (state, action) => {
        state.coupon = action.payload.data
      })
  }
})

export default couponsSlice.reducer
