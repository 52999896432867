// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getPrivacy = createAsyncThunk('privacyPolicy/getPrivacy', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/generalText?key=privacy_policy',
    {
      params
    }
  )
  return {
    params,
    data: response.data.data,
    loading: false
  }
})

// updatePrivacy
export const updatePrivacy = createAsyncThunk('privacyPolicy/updatePrivacy', async params => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/generalText', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })

  return params
})

// export const getTerms = createAsyncThunk('privacy/getTerms', async params => {
//   const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/generalText?key=terms', {
//     params
//   })
//   return {
//     params,
//     data: response.data.data,
//     loading: false
//   }
// })

export const privacyPolicySlice = createSlice({
  name: 'privacyPolicy',
  initialState: {
    privacyData: [],
    // termsData: [],
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPrivacy.fulfilled, (state, action) => {
      state.privacyData = action.payload.data
      state.loading = action.payload.loading
    })
    // .addCase(getTerms.fulfilled, (state, action) => {
    //   state.termsData = action.payload.data
    //   state.loading = action.payload.loading
    // })
  }
})

export default privacyPolicySlice.reducer
