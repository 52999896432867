// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getTerms = createAsyncThunk('terms/getTerms', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/generalText?key=terms', {
    params
  })
  return {
    params,
    data: response.data.data,
    loading: false
  }
})

// updateTerms
export const updateTerms = createAsyncThunk('terms/updateTerms', async params => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/superAdmin/generalText', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })

  return params
})

export const termsSlice = createSlice({
  name: 'terms',
  initialState: {
    termsData: [],
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTerms.fulfilled, (state, action) => {
      state.termsData = action.payload.data
      state.loading = action.payload.loading
    })
  }
})

export default termsSlice.reducer
